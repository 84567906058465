import React from "react"

import Logo from "./header/logo"
import Menu from "./header/menu"

const Header = ({ location }) => {
  const style = { padding: "20px 0 12px" }

  if (location && location.startsWith("/blog")) {
    // style.backgroundColor = "white"
    style.backgroundColor = "#f6f6f8"
    // style.borderBottom = "1px solid #e0e0e0"
  }

  return (
    <header style={style}>
      <div style={{ float: "left" }}>
        <Logo />
      </div>
      <div style={{ float: "right" }}>
        <Menu location={location} />
      </div>
      <div style={{ clear: "both" }}></div>
    </header>
  )
}

export default Header
