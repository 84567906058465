import { Link } from "gatsby"
import React from "react"

import styles from "./logo.module.scss"

const Logo = () => (
  <Link to="/">
    <div className={styles.logo}>
      Engrafa
      <br />
      <span>Dla biur rachunkowych</span>
    </div>
  </Link>
)

export default Logo
