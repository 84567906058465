import React from "react"
import { Link } from "gatsby"

import { Container, Row, Col } from "react-grid-system"

import * as styles from "./footer.module.scss"

const Footer = () => (
  <footer>
    <div className={styles.main}>
      <Container fluid>
        <Row>
          <Col sm={4} md={4} xl={2} offset={{ xl: 1 }}>
            <h5>Engrafa</h5>
            <p>
              System automatycznego odczytywania dokumentów księgowych i
              wspomagania pracy biura rachunkowego.
            </p>
          </Col>
          <Col sm={4} md={3} offset={{ md: 2, xl: 3 }}>
            <h5>Menu</h5>
            <ul>
              <li>
                <Link to="/">Strona główna</Link>
              </li>
              <li>
                <Link to="/#benefits">Funkcje programu</Link>
              </li>
              <li>
                <Link to="/cennik">Cennik</Link>
              </li>
              <li>
                <Link to="/#subscribe">Rejestracja</Link>
              </li>
              <li>
                <Link to="/polityka-prywatnosci">Polityka prywatności</Link>
              </li>
            </ul>
          </Col>
          <Col sm={4} md={3} offset={{ lg: 0 }}>
            <h5>Kontakt</h5>
            <p>kontakt@engrafa.pl</p>
            <p>
              Engrafa sp. z o.o.
              <br />
              ul. Kościuszki 29
              <br />
              50-011 Wrocław
              <br />
              NIP: 8971868515
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  </footer>
)

export default Footer
