import React from "react"

import * as styles from "./burger-icon.module.scss"

const BurgerIcon = ({ active, onToggle }) => {
  return (
    <div
      style={{
        zIndex: 1000,
        paddingRight: 20,
        paddingTop: 11,
      }}
    >
      <a
        style={{ zIndex: 1000 }}
        href="#"
        className={active ? styles.burger + " " + styles.active : styles.burger}
        onClick={ev => {
          if (onToggle) {
            onToggle()
          }
          ev.preventDefault()
        }}
      >
        <i style={{ zIndex: 1000 }}></i>
      </a>
    </div>
  )
}

export default BurgerIcon
