import { Link } from "gatsby"
import React, { useState } from "react"

import { Hidden, Visible } from "react-grid-system"

import BurgerIcon from "./burger-icon"

import * as styles from "./menu.module.scss"

const ResponsiveMenu = ({ active }) => {
  return (
    <div
      className={
        styles.responsiveMenu + (active ? " " + styles.responsiveActive : "")
      }
    >
      <Links />
    </div>
  )
}

const Links = ({ style, buttonStyle, location }) => (
  <div className={style}>
    <ul>
      <li className={location === "/cennik" ? styles.menuActive : undefined}>
        <Link to="/cennik">Cennik</Link>
      </li>
      <li
        className={
          location && location.startsWith("/blog")
            ? styles.menuActive
            : undefined
        }
      >
        <Link to="/blog/">Blog</Link>
      </li>
      <li>
        <Link to="/#subscribe" className={buttonStyle}>
          Załóż konto
        </Link>
      </li>
    </ul>
  </div>
)

const Menu = ({ location }) => {
  const [menuVisible, setMenuVisible] = useState(false)

  return (
    <>
      <Hidden xs sm>
        <Links
          style={styles.menu}
          buttonStyle={styles.button}
          location={location}
        />
      </Hidden>
      <Visible xs sm>
        <BurgerIcon
          active={menuVisible}
          onToggle={() => setMenuVisible(!menuVisible)}
        />
        <ResponsiveMenu active={menuVisible} />
      </Visible>
    </>
  )
}

export default Menu
