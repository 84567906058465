/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { ScreenClassProvider } from "react-grid-system"

import Header from "./header"
import Footer from "./footer"

import * as styles from "./layout.module.scss"

const Layout = ({ location, children, style }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ScreenClassProvider>
      <div className={styles.root} style={style}>
        <Header siteTitle={data.site.siteMetadata.title} location={location} />
        <main>{children}</main>
        <Footer />
      </div>
    </ScreenClassProvider>
  )
}

export default Layout
